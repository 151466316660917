import React, { useState, useEffect } from "react";
import profileCSS from "./profilePage.module.css";
import "./profilePage.module.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout, userLogin } from "../../redux/cartSlice";
import { updateProfile } from "../../api/api";
import { useMutation } from "@tanstack/react-query";
const ProfilePage = () => {
  const history = useNavigate();
  const user = useSelector((state) => state.cart.user);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const islogin = useSelector((state) => state.cart.isLogin);
  const [isEdit, setIsEdit] = useState(false);
  const [profile, setProfile] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    username: user.username,
    address: user.address,
    city: user.city,
    postcode: user.postcode,
    country: user.country,
  });
  const updateMutation = useMutation(updateProfile, {
    onError: (err) => {
      setError(err.response.data);
      console.log("the error:", err.response.data);
    },
    onSuccess: (theData) => {
      setIsEdit(!isEdit);
      dispatch(userLogin(theData.data));
    },
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  if (!islogin) {
    history("/login/sign_in");
  }
  function handleEdit() {
    setIsEdit(!isEdit);
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleLogOut() {
    dispatch(userLogout(null));
    history("/login/sign_in");
    console.log("dddd");
  }
  function handleUpadte(e) {
    e.preventDefault();
    updateMutation.mutate(profile);
  }
  return (
    <>
      <div className={profileCSS.header}>
        <Header />
      </div>

      <div className={profileCSS.profile}>
        <h4>account information</h4>

        <div className={profileCSS.profileDetails}>
          <form action="/update" method="post" onSubmit={handleUpadte}>
            <div className={profileCSS.label}>
              <div>
                <h4>email</h4>
                <p>{profile.username}</p>
              </div>
              <label>
                <div className={profileCSS.edit}>
                  <h4>Name</h4>
                  <h6 onClick={handleEdit}> {isEdit ? "cancel" : "edit"}</h6>
                </div>
                <h6 className={profileCSS.editable}>
                  {isEdit ? "edit your firstname and lastname" : null}
                </h6>
                <input
                  onChange={handleChange}
                  type="text"
                  className={profileCSS.input}
                  name="firstname"
                  placeholder="firstname"
                  value={profile.firstname}
                  pattern="^[a-zA-Z]+$"
                  title="Please enter a valid first name with only letters (spaces, numbers and special characters are not allowed)"
                  required
                  disabled={!isEdit}
                />
                <input
                  onChange={handleChange}
                  type="text"
                  placeholder="lastname"
                  className={profileCSS.input}
                  name="lastname"
                  value={profile.lastname}
                  pattern="^[a-zA-Z]+$"
                  title="Please enter a valid last name with only letters (spaces, numbers and special characters are not allowed)"
                  required
                  disabled={!isEdit}
                />
              </label>
            </div>

            <div className={profileCSS.label}>
              <label>
                <div className={profileCSS.edit}>
                  <h4>Shippong address</h4>
                  <h6 onClick={handleEdit}>{isEdit ? "cancel" : "edit"}</h6>
                </div>

                <h6 className={profileCSS.editable}>
                  {isEdit
                    ? "edit your address, city, country, and postcode"
                    : null}
                </h6>

                <div className={profileCSS.addressParent}>
                  <input
                    onChange={handleChange}
                    type="text"
                    className={profileCSS.input}
                    name="address"
                    value={profile.address}
                    placeholder="address"
                    required
                    disabled={!isEdit}
                  />

                  <input
                    onChange={handleChange}
                    type="text"
                    className={profileCSS.input}
                    name="city"
                    placeholder="city"
                    value={profile.city}
                    required
                    disabled={!isEdit}
                  />
                </div>
                <div className={profileCSS.addressParent}>
                  <input
                    onChange={handleChange}
                    type="text"
                    className={profileCSS.input}
                    name="country"
                    placeholder="country"
                    required
                    value={profile.country}
                    disabled={!isEdit}
                  />

                  <input
                    onChange={handleChange}
                    type="text"
                    className={profileCSS.input}
                    name="postcode"
                    placeholder="postcode"
                    value={profile.postcode}
                    required
                    disabled={!isEdit}
                  />
                </div>
              </label>
            </div>
            {isEdit ? (
              <div className={profileCSS.submit}>
                <h4 onClick={handleEdit}>cancel</h4>
                <input
                  type="submit"
                  value={
                    updateMutation.isLoading ? "loading.." : "update account"
                  }
                />
              </div>
            ) : (
              <div className={profileCSS.submit}>
                <h4 onClick={handleEdit}>update details</h4>

                <input onClick={handleLogOut} type="submit" value="Log out" />
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;
