import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import { googleAuth } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogin } from "../../redux/cartSlice";
const GoogleLoginPage = () => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const googleMutation = useMutation(googleAuth, {
    onError: (err) => {
      setError(err.response.data);
    },
    onSuccess: (theData) => {
      dispatch(userLogin(theData.data));
      history("/profile");
    },
  });

  function handleGoogleAuth(response) {
    var userObject = jwt_decode(response.credential);
    googleMutation.mutate(userObject);
  }
  useEffect(() => {
    /*  global google */
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleGoogleAuth,
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  const myStyle = {
    width: "100%",
  };
  return (
    <div>
      <div style={myStyle} id="signInDiv"></div>
      {googleMutation.isError ? <p>{error}</p> : null}
    </div>
  );
};

export default GoogleLoginPage;
