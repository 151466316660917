import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Map from "../../components/Map";
import AboutCSS from "./about.module.css";
import firstPicture from "../../assets/4.jpg";
import secondPicture from "../../assets/5.jpg";

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div>
      <div className={AboutCSS["about"]}>
        <div className={AboutCSS["about-hero"]}>
          <Header boolean={false} />
          <div className={AboutCSS["about-hero-section"]}>
            <h2>ABOUT SWEET SOUND</h2>
            <hr />

            <div className={AboutCSS["about-hero-text"]}>
              <h4>what we do</h4>
              <p>
                When it comes to gaming audio, nothing competes with Sweet
                Sound. In 2005, Sweet Sound pioneered the way for high-quality
                gaming audio with the very first console gaming headset – the
                X51 – and since then we’ve led the industry by designing
                innovative, best-in-class products that take gaming to the next
                level.
              </p>
              <p>
                Whether you’re just starting out, a casual player, a hardcore
                gamer or an esports pro, Sweet Sound has the best headset for
                you.
              </p>
            </div>
          </div>
        </div>
        <div className={AboutCSS["first-section"]}>
          <div className={AboutCSS["first-child"]}>
            <h3>OUR VALUES AND IDEA</h3>
            <h5> our focus is on providing the heightest quality</h5>
            <div className={AboutCSS["parent-text"]}>
              <div className={AboutCSS["first-text"]}>
                <p>
                  loremNisi commodo in dolore irure anim occaecat eiusmod
                  proident amet aute ipsum Lorem ut. Laboris consectetur officia
                  ea ea sunt cillum reprehenderit aute. Cillum in reprehenderit
                  officia ullamco minim cillum anim deserunt. Amet laboris minim
                  esse deserunt proident. Ea nostrud ipsum commodo consequat
                  adipisicing labore esse.
                </p>
                <p>
                  est excepteur mollit enim exercitation. Aute qui irure fugiat
                  ex magna dolore duis enim ea laboris sit nulla laborum amet.
                  Pariatur eiusmod dolor proident veniam quis cupidatat. Dolor
                  in aute sunt culpa ut culpa. Sunt adipisicing eu quis dolore
                  tempor. Consectetur ipsum officia aliqua aute excepteur.
                  Aliquip proident sint ad id incididunt magna anim minim do
                  amet. Cillum sunt pariatur aliqua ad nostrud est id esse sunt
                  dolore. Consequat amet aliqua quis nulla
                </p>
              </div>
              <div className={AboutCSS["second-text"]}>
                <p>
                  occaecat minim ex ullamco amet. Ipsum consectetur veniam
                  occaecat tempor proident magna fugiat minim. Sint eu ex fugiat
                  cupidatat deserunt sint non. Esse consequat voluptate
                  reprehenderit eiusmod esse dolore. Fugiat sit sit deserunt
                  pariatur sit qui Lorem labore. Quis excepteur qui irure et.
                  Qui duis et ad ipsum eu qui aliqua ad excepteur veniam
                  excepteur. Laborum ex ea labore deserunt elit
                </p>
                <p>
                  eiusmod nulla magna reprehenderit consequat aliquip
                  incididunt. Qui fugiat non non nulla ex voluptate quis officia
                  ex incididunt minim duis occaecat ullamco. Dolore fugiat elit
                  ea qui consectetur excepteur mollit deserunt minim. Ex
                  exercitation reprehenderit elit minim aute culpa commodo.
                  Irure enim incididunt est duis ad aliqua
                </p>
              </div>
            </div>
          </div>
          <img
            className={AboutCSS["second-child-image"]}
            src={firstPicture}
            alt="person"
          />
        </div>
        <div className={AboutCSS["second-section"]}>
          <h2>
            THE GUARANTEE OF THE QUALITY OF OUR PRODUCTS IS ITS SOUND AND BEATS
          </h2>
        </div>
        <div className={AboutCSS["third-section"]}>
          <img
            className={AboutCSS["second-child-image"]}
            src={secondPicture}
            alt=""
          />

          <div className={AboutCSS["third-section-parent-text"]}>
            <h3>
              Magna eiusmod ex qui aliquip Laborum culpa esse enim cupidatat ea.
              Dolor duis consequat amet occaecat consequat magna ea ipsum.
            </h3>
            <p>
              Deserunt est ad eiusmod sit. Nulla sunt dolor eiusmod occaecat id
              amet cupidatat eu ex proident velit duis. Aliqua sint velit ad
              eiusmod qui fugiat exercitation proident. Cillum eu sunt aliquip
              ad consectetur adipisicing tempor ullamco. Ut ex pariatur aliqua
              commodo commodo occaecat aliqua sint. Aliquip adipisicing veniam
              veniam officia duis. Fugiat ut exercitation est officia ipsum
              velit non quis aute tempor id aute in. Ad mollit eiusmod aliquip
              ullamco. Pariatur cupidatat laborum laborum aliquip eiusmod veniam
              amet. Nulla excepteur cupidatat elit non in exercitation anim qui
              consectetur. Ex enim voluptate non occaecat dolore elit nulla
              voluptate ipsum consectetur elit tempor. Non velit laboris dolore
              et duis. Sint est labore anim velit nisi pariatur laborum elit
              cillum et. Deserunt quis enim et ea qui reprehenderit nulla
              commodo dolor sint voluptate exercitation aliquip mollit. Tempor
              ex dolor magna deserunt tempor. Irure consectetur ex proident
              mollit in. Velit aliqua proident cupidatat laborum dolor mollit
              aliqua cillum. Ut id cupidatat aliqua cupidatat. Officia eu
              consequat sit sunt consectetur magna velit quis ipsum veniam
              pariatur. Occaecat anim minim laborum deserunt ex cupidatat mollit
              officia cillum aute nostrud. Ad officia laborum mollit duis
              voluptate eu excepteur est commodo proident et officia nisi elit.
              Veniam non id sint minim ea nostrud proident Lorem proident et
              enim Lorem ea do. Eu commodo ipsum velit ea.
            </p>
          </div>
        </div>

        <Map />
        <Footer />
      </div>
    </div>
  );
}

export default About;
