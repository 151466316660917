import React, { useEffect, useRef, useState } from "react";
import bagCSS from "./bagItem.module.css";
import { FaTimes } from "react-icons/fa";
import { removeFromCart } from "../redux/cartSlice";
import { useSelector, useDispatch } from "react-redux";
const BagItem = (prop) => {
  const cart = useSelector((state) => state.cart.value);

  const [products, setProducts] = useState(cart);
  const dispatch = useDispatch();
  const container = useRef(null);
  function changeWidth() {
    const element = container.current;
    if (element) {
      if (products.length > 2) {
        element.style.height = "300px";
      } else if (element) {
        element.style.height = "fit-content";
      }
    }
  }
  useEffect(() => {
    changeWidth();
  });
  function totalPrice() {
    let priceList = cart.map(
      (element) => +element.newPrice.replace(/[$,]/g, "")
    );
    if (priceList.length > 0) {
      let sum = priceList.reduce((acc, current) => acc + current);

      return "$" + sum;
    }
  }
  function handleDelete(id) {
    dispatch(removeFromCart(id));
    changeWidth();
    setProducts(cart);
  }
  return (
    <>
      <div className={bagCSS["bagItem"]}>
        <div ref={container} className={bagCSS["bagItem-section"]}>
          {cart.map((element, index) => (
            <div key={index} className={bagCSS["bagItem-container-section"]}>
              <div className={bagCSS["bagItem-container"]} key={element.id}>
                <img src={element.images} alt={element.name} />
                <div className={bagCSS["bagItem-container-details"]}>
                  <h4>{element.name}</h4>
                  <p>{element.vendor}</p>
                  <h5>
                    color: <span>{element.color}</span>
                  </h5>
                  <h5>
                    type: <span>{element.type}</span>
                  </h5>
                  <h5>
                    quantity: <span>{element.numOfOrder}</span>
                  </h5>
                </div>
                <div className={bagCSS["bagItem-container-price"]}>
                  <h3>{element.newPrice}</h3>
                  <FaTimes
                    className={bagCSS["bagItem-times"]}
                    onClick={() => handleDelete(element.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={bagCSS["bag-price"]}>
          <h3>Total</h3>
          <h3> {totalPrice()}</h3>
        </div>
      </div>
    </>
  );
};

export default BagItem;
