import React, { useState, useEffect, useRef } from "react";
import filterCSS from "./filter.module.css";
import ProductCard from "./Product-card";
import { Data } from "../data";
import { BiSliderAlt, BiX } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import no_result from "../assets/no_result.jpg";
import { HashLink } from "react-router-hash-link";
import { TbMathGreater } from "react-icons/tb";

function Filter(props) {
  const location = useLocation();
  const { state } = location;
  const productContainer = useRef(null);
  let span = useRef(null);
  let botton = useRef(null);
  let backGround = useRef(null);
  const slider = {
    margin: "auto 0",
    fontSize: "x-large",
  };
  const [filter, setFilter] = useState({
    minRange: 0,
    maxRange: 5000,
    wireless: false,
    earbuds: false,
    hybrid: false,
    wired: false,
    astellkern: false,
    audeze: false,
    audio: false,
    focal: false,
  });
  const [visibleProduct, setVisibleProduct] = useState(12);
  const [data, setData] = useState(state);
  const [seeMoreBoolean, setSeeMoreBoolean] = useState(false);
  const [myData, setmyData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    async function getData() {
      let result = await Data;
      setmyData(result);
    }
    getData();
    setVisibleProduct(12);
  }, []);
  useEffect(() => {
    if (state) {
      setData(state);
    } else {
      async function getData() {
        let result = await Data;

        setData(result);
      }
      getData();
    }
  }, []);

  if (!myData && !data) {
    return <Loading />;
  }
  var colorsToFilter = [];
  const numberOfProduct = data.filter((element) =>
    props.search === ""
      ? element
      : element.name.toLowerCase().includes(props.search.toLowerCase())
  ).length;

  function checkReadMore() {
    setSeeMoreBoolean(
      productContainer.current?.childElementCount < data.length
    );
  }

  function handleFilter(e) {
    e.preventDefault();
    // price filter
    let priceFilter = myData.filter((element) => {
      let price = element.price;
      price = price.replace("$", "");
      price = price.replace(",", "");
      return price <= filter.maxRange && price >= filter.minRange;
    });

    //  types filter
    if (filter.wireless) {
      var wirelessFilter = priceFilter.filter(
        (element) => element.type.toLowerCase() === "wireless"
      );
    }
    if (filter.earbuds) {
      var earbudsFilter = priceFilter.filter(
        (element) => element.type.toLowerCase() === "earbuds"
      );
    }
    if (filter.hybrid) {
      var hybridFilter = priceFilter.filter(
        (element) => element.type.toLowerCase() === "hybrid"
      );
    }
    if (filter.wired) {
      var wiredFilter = priceFilter.filter(
        (element) => element.type.toLowerCase() === "wired"
      );
    }
    // join all type filterd together
    var emptyarray = [];
    const typeFilter = emptyarray.concat(
      wirelessFilter,
      earbudsFilter,
      hybridFilter,
      wiredFilter
    );
    // remove the  undefined
    var filteredArray = typeFilter.filter((elememt) => elememt !== undefined);
    if (filteredArray.length === 0) {
      filteredArray = priceFilter;
    }

    // vendor filter
    if (filter.astellkern) {
      var astellkernFilter = filteredArray.filter(
        (element) => element.vendor.toLowerCase() === "astell&kern"
      );
    }
    if (filter.audeze) {
      var audezeFilter = filteredArray.filter(
        (element) => element.vendor.toLowerCase() === "audeze"
      );
    }
    if (filter.audio) {
      var audioFilter = filteredArray.filter((element) => {
        let vendor = element.vendor.toLowerCase();
        vendor = vendor.replace("64", "");
        vendor = vendor.replace(" ", "");
        return vendor === "audio";
      });
    }
    if (filter.focal) {
      var focalFilter = filteredArray.filter(
        (element) => element.vendor.toLowerCase() === "focal"
      );
    }
    //  join all vendor filterd together
    const vendorFilter = emptyarray.concat(
      astellkernFilter,
      audezeFilter,
      audioFilter,
      focalFilter
    );

    // remove the undefined
    var filteredVendorArray = vendorFilter.filter(
      (elememt) => elememt !== undefined
    );
    if (filteredVendorArray.length === 0) {
      filteredVendorArray = filteredArray;
    }
    // colors filter
    var filteredColorv = filteredVendorArray.filter((element) =>
      colorsToFilter.includes(element.color)
    );
    if (filteredColorv.length === 0) {
      filteredColorv = filteredVendorArray;
    }

    let colors = span.current.getElementsByClassName(filterCSS.clicked);

    for (let index = 0; index < 6; index++) {
      Array.prototype.forEach.call(colors, (dot) => {
        dot.classList.remove(filterCSS.clicked);
      });
    }

    setVisibleProduct(15);
    setData(filteredColorv);
    colorsToFilter = [];
    handleFilterClick();
  }
  function handleCheckBox(e) {
    const { name, checked } = e.target;
    setFilter({ ...filter, [name]: checked });
  }
  function clicked(e, color) {
    e.target.classList.toggle(filterCSS.clicked);
    let index = colorsToFilter.indexOf(color);
    if (index > -1) {
      colorsToFilter.splice(index, 1);
    } else {
      colorsToFilter.push(color);
    }
  }
  function handleRangeChange(e) {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  }

  function handleFilterClick() {
    if (botton.current) {
      botton.current.classList.toggle(filterCSS.block);
      document
        .getElementsByTagName("body")[0]
        .classList.toggle(filterCSS.scroll);
      backGround.current.classList.toggle(filterCSS.closeFilter);
    }
  }
  function HandleSeeMore() {
    setVisibleProduct(visibleProduct + 12);
  }
  function handleReset() {
    setFilter({
      minRange: 0,
      maxRange: 5000,
      wireless: false,
      earbuds: false,
      hybrid: false,
      wired: false,
      astellkern: false,
      audeze: false,
      audio: false,
      focal: false,
    });
    let colors = span.current.getElementsByClassName(filterCSS.clicked);

    for (let index = 0; index < 6; index++) {
      Array.prototype.forEach.call(colors, (dot) => {
        dot.classList.remove(filterCSS.clicked);
      });
    }
    colorsToFilter = [];
    handleFilterClick();
  }
  return (
    <div>
      <div className={filterCSS["all-product"]}>
        <div className={filterCSS["shop-page-nav-container"]}>
          <div className={filterCSS["shop-page-nav"]}>
            <HashLink to="/market#collection">
              <h3>Collection</h3>
            </HashLink>

            <TbMathGreater />
            <h3
              onClick={() =>
                navigate("/shop", {
                  state: myData,
                })
              }
            >
              All product
            </h3>
            {props.search ? (
              <div className={filterCSS["search-nav"]}>
                <TbMathGreater />
                <h3 className={filterCSS["search-text"]}>{props.search}</h3>
              </div>
            ) : null}
          </div>
          <div
            className={filterCSS["mobile-filter"]}
            onClick={handleFilterClick}
          >
            <h3>FIlter</h3>
            <BiSliderAlt style={slider} />
          </div>
        </div>

        <div className={filterCSS["all-product-section"]}>
          <div ref={backGround} onClick={handleFilterClick}></div>
          <div ref={botton} className={filterCSS["filter"]}>
            <div className={filterCSS["filter-top"]}>
              <h3>filters</h3>{" "}
              <BiX
                onClick={handleFilterClick}
                className={filterCSS["filter-cancel-icon"]}
              />
            </div>

            <form onSubmit={(e) => handleFilter(e)}>
              <div className={filterCSS["form-first-child"]}>
                <div>
                  <h4>price</h4>
                  <div className={filterCSS["price-input-container"]}>
                    <input
                      className={filterCSS["price-input"]}
                      type="number"
                      name="mixRange"
                      onChange={handleRangeChange}
                      value={filter.minRange}
                    />
                    <input
                      className={filterCSS["price-input"]}
                      type="number"
                      name="maxRange"
                      value={filter.maxRange}
                      onChange={handleRangeChange}
                    />
                  </div>
                  <div className={filterCSS["range-container"]}>
                    <input
                      type="range"
                      name="minRange"
                      min="0"
                      max="5000"
                      value={filter.minRange}
                      id="dual-range-slider"
                      onChange={handleRangeChange}
                    />
                    <input
                      type="range"
                      min="0"
                      name="maxRange"
                      max="5000"
                      value={filter.maxRange}
                      id="dual-range-slider-2"
                      onChange={handleRangeChange}
                    />
                  </div>
                </div>
                <div>
                  <h4>type</h4>
                  <input
                    type="checkbox"
                    id="wireless"
                    name="wireless"
                    checked={filter.wireless}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="wireless">wireless (Bluetooth)</label>
                  <br />
                  <input
                    type="checkbox"
                    id="Earbuds"
                    name="earbuds"
                    checked={filter.earbuds}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="Earbuds">Earbuds (in-ear)</label>
                  <br />
                  <input
                    type="checkbox"
                    id="hybrid"
                    name="hybrid"
                    checked={filter.hybrid}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="hybrid">hybrid</label>
                  <br />
                  <input
                    type="checkbox"
                    id="wired"
                    name="wired"
                    checked={filter.wired}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="wired">wired</label>
                  <br />
                  <input
                    type="checkbox"
                    id="Noise-cancelling"
                    checked={filter.earbuds}
                    onChange={handleCheckBox}
                    disabled
                  />
                  <label htmlFor="Noise-cancelling">Noise-cancelling</label>
                  <br />
                </div>
                <div>
                  <h4>brand</h4>
                  <input
                    type="checkbox"
                    className={filterCSS["checkbox"]}
                    id="64-audio"
                    name="audio"
                    checked={filter.audio}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="64-audio">64 AUDIO </label>
                  <br />
                  <input
                    type="checkbox"
                    className={filterCSS["checkbox"]}
                    id="astell&kern"
                    name="astellkern"
                    checked={filter.astellkern}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="astell&kern">ASTELL&KERN</label>
                  <br />
                  <input
                    type="checkbox"
                    className={filterCSS["checkbox"]}
                    id="audeze"
                    name="audeze"
                    checked={filter.audeze}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="audeze">AUDEZE</label>
                  <br />
                  <input
                    type="checkbox"
                    className={filterCSS["checkbox"]}
                    id="focal"
                    name="focal"
                    checked={filter.focal}
                    onChange={handleCheckBox}
                  />
                  <label htmlFor="focal">FOCAL</label>
                  <br />
                </div>
              </div>

              <div id="color">
                <h4>colors</h4>
                <div ref={span} className={filterCSS["colors"]}>
                  <span
                    data-color="black"
                    onClick={(e) => clicked(e, "black")}
                  ></span>
                  <span
                    data-color="gray"
                    onClick={(e) => clicked(e, "grey")}
                  ></span>
                  <span
                    data-color="brown"
                    onClick={(e) => clicked(e, "brown")}
                  ></span>
                  <span
                    data-color="green"
                    onClick={(e) => clicked(e, "green")}
                  ></span>
                  <span
                    data-color="red"
                    onClick={(e) => clicked(e, "red")}
                  ></span>
                  <span
                    data-color="white"
                    onClick={(e) => clicked(e, "white")}
                  ></span>
                </div>
              </div>
              <div className={filterCSS["submit-container"]}>
                <input
                  onClick={handleReset}
                  className={filterCSS["submit-container-btn"]}
                  type="button"
                  value="reset"
                />
                <input
                  className={filterCSS["submit-container-btn"]}
                  type="submit"
                  value="submit"
                />
              </div>
            </form>
          </div>

          <div className={filterCSS["the-second-child"]} onLoad={checkReadMore}>
            {numberOfProduct === 0 ? (
              <div className={filterCSS["no-search"]}>
                <img src={no_result} alt="no result from search" />
              </div>
            ) : (
              <div
                ref={productContainer}
                className={filterCSS["all-product-container"]}
              >
                {data
                  .filter((element) =>
                    props.search === ""
                      ? element
                      : element.name
                          .toLowerCase()
                          .includes(props.search.toLowerCase())
                  )
                  .slice(0, visibleProduct)
                  .map((element) => (
                    <ProductCard product={element} key={element.id} />
                  ))}
              </div>
            )}
            {data.length >= visibleProduct ? (
              <div>
                {seeMoreBoolean ? (
                  <div className={filterCSS["show-more"]}>
                    <p className="btn " onClick={HandleSeeMore}>
                      Show More
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
