import React from "react";
import loadingGIF from "../assets/loading.gif";
import loadingCSS from "./Loading.module.css";
const Loading = () => {
  return (
    <div className={loadingCSS.container}>
      <img src={loadingGIF} alt="LOADING..." />
      <p className={loadingCSS.text}>
        your connection is either slow or bad. check your INTERNET and reloAD
        THIS PAGE
      </p>
    </div>
  );
};

export default Loading;
