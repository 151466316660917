import { useState, useRef, useEffect } from "react";
import deliveryCSS from "./delivery.module.css";
import { useNavigate, useOutletContext } from "react-router-dom";

const Delivery = () => {
  const history = useNavigate();
  const [details, setDetails] = useOutletContext();
  const [pickedDeliveryMethod, setPickedDeliveryMethod] = useState(false);
  const [deliveryMethod, setdeliveryMethod] = useState({
    deliveryMethod: "",
  });
  const StandartOption = useRef(null);
  const StoreOption = useRef(null);
  const button = useRef(null);

  useEffect(() => {
    if (!details.user) {
      history("/checkout");
    }
  });

  function selectMethod(index, string) {
    setdeliveryMethod({
      deliveryMethod: string,
    });
    setPickedDeliveryMethod(true);
    if (StoreOption) {
      StoreOption.current.classList.remove(deliveryCSS.picked);
      StandartOption.current.classList.remove(deliveryCSS.picked);
      if (index === 1) {
        StoreOption.current.classList.add(deliveryCSS.picked);
      } else if (index === 0) {
        StandartOption.current.classList.add(deliveryCSS.picked);
      }
    }
  }
  function handleSubmit() {
    setDetails((prevState) => ({
      ...prevState,
      deliveryMethod: deliveryMethod.deliveryMethod,
    }));

    history("/checkout/confirm");
  }
  return (
    <>
      <div className={deliveryCSS.personal}>
        <h4>select delivery method</h4>
      </div>
      <div
        ref={StandartOption}
        onClick={() => selectMethod(0, "Standart")}
        className={deliveryCSS.option}
      >
        <h4>standart</h4>
        <p>4 to 5 working days </p>
        <h5>free</h5>
      </div>
      <div
        ref={StoreOption}
        onClick={() => selectMethod(1, "collect in store")}
        className={deliveryCSS.option}
      >
        <h4>collect in store</h4>
        <p>3 working days </p>
        <h5>free</h5>
      </div>
      <button
        ref={button}
        onClick={pickedDeliveryMethod ? handleSubmit : null}
        className={
          pickedDeliveryMethod ? deliveryCSS.selected : deliveryCSS.button
        }
      >
        {pickedDeliveryMethod
          ? "continue to delivery"
          : "select a method to proceed"}
      </button>
    </>
  );
};

export default Delivery;
