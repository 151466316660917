import Header from "../components/Header";
import Footer from "../components/Footer";
import { HiPaperAirplane } from "react-icons/hi2";
import { React, useState, useEffect } from "react";
import home from "./home.module.css";
import aboutHeadphone from "../assets/about-headphone.jpg";
import Testimonial from "../components/Testimonial";
import { Link } from "react-router-dom";
import Specification from "../components/specification";
import Collection from "../components/collection";
import { Data } from "../data";
import Loading from "../components/Loading";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Home() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [myData, setmyData] = useState(null);
  const [ref, inView] = useInView({
    threshold: 0.1, // Percentage of the div that must be in view to trigger the animation
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    async function getData() {
      const result = await Data;
      setmyData(result);
    }
    getData();
  }, []);
  if (!myData) {
    return <Loading />;
  }
  const newArival = myData.filter(
    (element) => element.vendor.toLowerCase() === "focal"
  );
  function handleChange(e) {
    const value = e.target.value;
    setEmail(value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    console.log(error);
  }
  function handleBlur(e) {
    let value = e.target.value;
    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      value
    );
    if (isValid) {
      setError(null);
    } else if ((value = "")) {
      setError(null);
    } else {
      setError("Invalid email address");
    }
  }
  const animationVariants = {
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: -100,
    },
  };
  return (
    <div className={home["app"]}>
      <div className={home["hero-parent"]}>
        <Header hasError boolean={false} />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duraton: 1.5 }}
          className={home["hero-banner"]}
        ></motion.div>
        <div className={home["hero"]}>
          <motion.div
            initial={{ x: -1000 }}
            animate={{ x: 0 }}
            exit={{ x: 1000 }}
            transition={{ duraton: 2 }}
            className={home["section-1"]}
          >
            <h2>
              Experience Quality{" "}
              <span className={home["media-580"]}>Performance.</span>
            </h2>
            <p>
              Experience sound like never before with our headphones, Discover
              the perfect fit for your ears. Unmatched comfort and sound quality
              in every headset
            </p>

            <p className={home["btn-p"]}>
              <Link to="shop">
                <div className={home["btn"]}>
                  <span>Go To Products</span> <HiPaperAirplane />
                </div>
              </Link>
            </p>
          </motion.div>
        </div>
      </div>

      <Collection collectionType="new Arrivals" collection={newArival} />
      <Specification />
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.8 }}
        className={home["about"]}
      >
        <div className={home["about-section-1"]}>
          <img
            className={home["about-picture"]}
            src={aboutHeadphone}
            alt="about headphone"
          />
        </div>
        <div className={home["about-section-2"]}>
          <h2>
            Learn <span className="green">About Us</span>
          </h2>
          <p>DISCOVER YOUR BEST SOUND YET WITH US</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit
            amet tincidunt leo, et efficitur est. Nam nec ultricies neque, ac
            volutpat leo. Pellentesque consequat neque in elementum sodales. Sed
            feugiat gravida luctus. Proin faucibus libero tellus, sed laoreet
            odio tempor sed. Cras malesuada rutrum turpis, quis ornare elit
            posuere a.
          </p>

          <p className={home["btn2"]}>
            <Link to="/about">Read more</Link>
          </p>
        </div>
      </motion.div>

      <div className={home["get-in-touch"]}>
        <div className={home["get-in-touch-first-child"]}>
          <h2>
            Let's keep <span className="green">in Touch</span>
          </h2>
          <p>sign up for emails and get free shipping on your first order</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            name="email"
            type="email"
            placeholder="Email"
            value={email}
          />
          <p className={home["error"]}> {error ? error : null}</p>
          <input className={home["btn2"]} type="submit" value="Submit" />
        </form>
      </div>
      <div>
        <Testimonial />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
