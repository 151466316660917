import { React, useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useMutation } from "@tanstack/react-query";
import Map from "../../components/Map";
import ContactCss from "./contact.module.css";
import { contactRoute } from "../../api/api";

function Contact() {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    number: "",
  });
  const [feedback, setFeedBack] = useState(null);
  const contactMutation = useMutation(contactRoute, {
    onError: (err) => {
      setFeedBack(err);
    },
    onSuccess: (theData) => {
      setFeedBack(theData);
    },
  });
  function handleBlur(e) {
    let { name, value } = e.target;
    let isValid;
    switch (name) {
      case "name":
        isValid = /^[a-zA-Z]+$/.test(value);
        isValid
          ? setError({ ...error, name: "" })
          : setError({
              ...error,
              name: "Please enter a valid name with only letters and spaces (numbers and special characters are not allowed)",
            });
        break;
      case "email":
        isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          value
        );
        isValid
          ? setError({ ...error, email: "" })
          : setError({
              ...error,
              email: "please enter a valid email",
            });
        break;
      case "number":
        isValid = /^\+?[0-9()-\s]+$/.test(value);
        isValid
          ? setError({ ...error, number: "" })
          : setError({
              ...error,
              number: "please enter a phone number",
            });
        break;
      default:
    }
  }
  function handleInputChange(e) {
    let { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  }
  function handleSubmit(e) {
    e.preventDefault();
    contactMutation.mutate(contact);
    setFeedBack(null);
    // setContact({
    //   name: "",
    //   email: "",
    //   number: "",
    //   message: "",
    // });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div>
      <div className={ContactCss["contact-hero"]}>
        <Header boolean={false} />
        <div className={ContactCss["contact-hero-text"]}>
          <h2>contact us</h2>
        </div>
      </div>

      <div className={ContactCss["section"]}>
        <div className={ContactCss["first-child"]}>
          <h2>HELLO! WE WOULD LOVE TO HEAR FROM YOU!</h2>
          <p>
            PLEASE FEEL FREE TO SEND US feedback as message AND WE WOULD GET
            BACK TO YOU :)
          </p>
        </div>
        <form
          className={ContactCss["second-child"]}
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            className={ContactCss["input"]}
            onChange={(e) => handleInputChange(e)}
            name="name"
            type="text"
            placeholder="NAME"
            value={contact.name}
            pattern="^[a-zA-Z]+$"
            title="Please enter a valid name with only letters (spaces, numbers and special characters are not allowed)"
            required
          />

          <input
            className={ContactCss["input"]}
            onChange={(e) => handleInputChange(e)}
            name="email"
            type="email"
            placeholder="EMAIL"
            value={contact.email}
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            title="enter a valid email"
            required
          />

          <input
            className={ContactCss["input"]}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => handleBlur(e)}
            name="number"
            type="number"
            placeholder="PHONE"
            value={contact.number}
            pattern="^\+?[0-9()-\s]+$"
            title="enter a valid phone number"
            required
          />

          <textarea
            className={ContactCss["textarea"]}
            name="message"
            onChange={(e) => handleInputChange(e)}
            placeholder="MESSAGE"
            value={contact.message}
            rows={3}
            required
          ></textarea>
          <button className={ContactCss["submit"]} type="submit">
            Submit
          </button>

          <p className={ContactCss["feedback"]}>{feedback}</p>
        </form>
      </div>
      <Map />
      <Footer />
    </div>
  );
}

export default Contact;
