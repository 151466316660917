import React, { useEffect, useRef } from "react";
import collectionCSS from "./collection.module.css";
import ProductCard from "./Product-card";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Collection = (props) => {
  const collectionElement = useRef(null);
  const heading = useRef(null);
  const leftArrow = useRef(null);
  const rightArrow = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.2, // Percentage of the div that must be in view to trigger the animation
  });
  useEffect(() => {
    styleLastWord();
  }, []);

  function styleLastWord() {
    const h3 = heading.current.textContent.split(" ");
    const lastWord = h3.pop();
    const addClass = `<span>${lastWord}</span>`;
    heading.current.innerHTML = h3.join(" ") + " " + addClass;
  }
  function rightClick() {
    let box = collectionElement.current;
    let totalWidthn = box.children.length * box.children[0].offsetWidth;

    if (box) {
    }
    let x = "";
    x = Math.floor(box.offsetWidth / 2 + box.scrollLeft);

    box.scrollTo({
      left: x,
      behavior: "smooth",
    });

    leftArrow.current.classList.remove(collectionCSS.hidden);

    if (totalWidthn <= x || totalWidthn <= x + 200) {
      rightArrow.current.classList.add(collectionCSS.hidden);
    }
  }
  function leftClick() {
    let box = collectionElement.current;
    let x = "";
    x = Math.floor(box.offsetWidth / 2 - box.scrollLeft);
    box.scrollTo({
      left: -x,
      behavior: "smooth",
    });

    rightArrow.current.classList.remove(collectionCSS.hidden);
    if (-x <= 0) {
      leftArrow.current.classList.add(collectionCSS.hidden);
    }
  }
  const animationVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 50,
    },
  };

  return (
    <>
      <motion.div
        ref={ref}
        initial='hidden'
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.8 }}
        className={collectionCSS["collection"]}>
        <div className={collectionCSS["collection-heading"]}>
          <h3 ref={heading}>{props.collectionType}</h3>
          <div className={collectionCSS["collection-icons"]}>
            <div
              onClick={leftClick}
              ref={leftArrow}
              className={collectionCSS["collection-icon"]}>
              <HiArrowLongLeft
                className={collectionCSS["collection-icon-icon"]}
              />
            </div>
            <div
              onClick={rightClick}
              ref={rightArrow}
              className={collectionCSS["collection-icon"]}>
              <HiArrowLongRight
                className={collectionCSS["collection-icon-icon"]}
              />
            </div>
          </div>
        </div>
        <hr className={collectionCSS["collection-line"]} />
        <div
          ref={collectionElement}
          className={collectionCSS["collection-cards"]}>
          {props.collection.map((element, index) => (
            <ProductCard product={element} key={index} />
          ))}
        </div>
        <hr className={collectionCSS["collection-line"]} />
      </motion.div>
    </>
  );
};

export default Collection;
