import React, { useEffect, useState } from "react";
import confirmCSS from "./confirm.module.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { stripeCheckout } from "../api/api";
import { useSelector } from "react-redux";
const Confirm = () => {
  const state = useSelector((state) => state.cart.value);
  const history = useNavigate();
  const [details, setDetails] = useOutletContext();
  const [error, setError] = useState("");
  const stripeMutation = useMutation(stripeCheckout, {
    onError: (err) => {
      console.log("the error:", err);
    },
    onSuccess: (theData) => {
      window.location.href = theData;
    },
  });
  function handleSubmit(e) {
    e.preventDefault();

    const stripe = {
      details: details,
      cart: state,
    };
    if (state.length < 1) {
      setError("You can't checkout if you have an empty cart");
    } else {
      stripeMutation.mutate(stripe);
    }
  }
  useEffect(() => {
    if (!details.user) {
      history("/checkout");
    } else if (!details.deliveryMethod) {
      history("/checkout");
    }
  });

  return (
    <>
      <div className={confirmCSS.personal}>
        <h4>confrim your details</h4>
      </div>
      <div className={confirmCSS.option}>
        <h5>Delivery to</h5>
        <div className={confirmCSS.optionName}>
          <p>{details.user?.firstname}</p>
          <p>{details.user?.lastname}</p>
        </div>
        <p>
          {details.user?.address}, {details.user?.postcode},{" "}
          {details.user?.city}, {details.user?.country}
        </p>
        <p>{details.user?.email} </p>
        <p
          className={confirmCSS.cancel}
          onClick={() => history("/checkout/details", { state: details.user })}
        >
          change
        </p>
      </div>
      <div className={confirmCSS.option}>
        <h5>delivery method</h5>
        <p>{details?.deliveryMethod}</p>

        <p
          onClick={() => history("/checkout/delivery")}
          className={confirmCSS.cancel}
        >
          change{" "}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <button type="submit" className={confirmCSS.button}>
          {stripeMutation.isLoading ? " Loading..." : "Proceed to payment"}
        </button>
        <span className={confirmCSS.error}>{error}</span>
      </form>
    </>
  );
};

export default Confirm;
