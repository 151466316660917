import React from "react";
import specificationCSS from "./specification.module.css";
import { CgHeadset } from "react-icons/cg";
import { BsEar } from "react-icons/bs";
import { GiSoundOn, GiSoundWaves } from "react-icons/gi";
import { Link } from "react-router-dom";
import specificationImage from "../assets/specification.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const Specification = () => {
  const [ref, inView] = useInView({
    threshold: 0.2, // Percentage of the div that must be in view to trigger the animation
  });
  const animationVariants = {
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: 100,
    },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
      transition={{ duration: 0.8 }}
      className={specificationCSS["specification"]}
    >
      <div className={specificationCSS["specification-first-section"]}>
        <div className={specificationCSS["specification-image-section"]}>
          <img
            className={specificationCSS["specification-image"]}
            src={specificationImage}
            alt="gaming-headset"
          />
        </div>
      </div>
      <div className={specificationCSS["specification-second-section"]}>
        <h4>Our product's specification</h4>
        <div className={specificationCSS["specification-lists"]}>
          <div className={specificationCSS["specification-list"]}>
            <CgHeadset
              className={specificationCSS["specification-lists-icons"]}
            />
            <div className={specificationCSS["specification-lists-details"]}>
              <h3>noise-cancelling feature</h3>
            </div>
          </div>
          <div className={specificationCSS["specification-list"]}>
            <BsEar className={specificationCSS["specification-lists-icons"]} />
            <div className={specificationCSS["specification-lists-details"]}>
              <h3>prevent eardrum damage</h3>
            </div>
          </div>
          <div className={specificationCSS["specification-list"]}>
            <GiSoundOn
              className={specificationCSS["specification-lists-icons"]}
            />
            <div className={specificationCSS["specification-lists-details"]}>
              <h3>quality of sound</h3>
            </div>
          </div>
          <div className={specificationCSS["specification-list"]}>
            <GiSoundWaves
              className={specificationCSS["specification-lists-icons"]}
            />
            <div className={specificationCSS["specification-lists-details"]}>
              <h3>bass friendly</h3>
            </div>
          </div>
        </div>
        <Link to="/shop">
          <button className={specificationCSS["btn"]}> shop now</button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Specification;
