import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import successfulCSS from "./successful.module.css";
import Collection from "../../components/collection";
import { Data } from "../../data";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Successful() {
  const history = useNavigate();
  const [myData, setmyData] = useState(null);
  const cart = useSelector((state) => state.cart.value);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    async function getData() {
      const result = await Data;
      setmyData(result);
    }
    getData();
  }, []);
  if (!myData) {
    return <Loading />;
  }
  const newArival = myData.filter((element) =>
    [1, 12, 7, 16, 3, 4, 7, 18].includes(element.id)
  );
  function totalPrice() {
    let priceList = cart.map(
      (element) => +element.newPrice.replace(/[$,]/g, "")
    );
    if (priceList.length > 0) {
      let sum = priceList.reduce((acc, current) => acc + current);
      return sum;
    }
  }
  return (
    <>
      <div className={successfulCSS["header"]}>
        <Header />
      </div>
      <div className={successfulCSS["successful-body"]}>
        <h3>Thank you!</h3>
        <h3>Your order information:</h3>
        <div>
          <p className={successfulCSS["order"]}>
            Your order number is - THFD_837654663
          </p>
          <div className={successfulCSS["order-container"]}>
            {cart.map((element, index) => (
              <div key={index} className={successfulCSS["cart"]}>
                <div className={successfulCSS["cart-details"]}>
                  <img src={element.images} alt={element.name} />
                  <p>{element.name}</p>
                </div>

                <p>{element.newPrice}</p>
              </div>
            ))}
            <div className={successfulCSS["total"]}>
              <p>total</p>
              <p>{totalPrice()} USD</p>
            </div>
            <div className={successfulCSS["center"]}>
              <button
                onClick={() => history("/")}
                className={successfulCSS["btn"]}
              >
                Go to the main page
              </button>
            </div>
          </div>
        </div>
      </div>
      <Collection collectionType="you might also like" collection={newArival} />
      <Footer />
    </>
  );
}
