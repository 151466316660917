import React, { useRef, useState, useEffect } from "react";
import cartCSS from "./cart.module.css";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { FaShippingFast } from "react-icons/fa";
import { BsFileMinus, BsFilePlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, removeFromCart } from "../redux/cartSlice";
import emptyCartImage from "../assets/empty-cart.png";
function Cart(props) {
  const arrayOfId = useSelector((state) => state.cart.value);
  const navigate = useNavigate();
  const [isTrue, setTrue] = useState(true);
  const container = useRef();
  const dispatch = useDispatch();
  function changeWidth() {
    const element = container.current;
    if (element) {
      if (arrayOfId.length > 2) {
        element.style.height = "270px";
      } else if (element) {
        element.style.height = "fit-content";
      }
    }
  }
  useEffect(() => {
    changeWidth();
  });
  function totalPrice() {
    let priceList = arrayOfId.map(
      (element) => +element.newPrice.replace(/[$,]/g, "")
    );
    if (priceList.length > 0) {
      let sum = priceList.reduce((acc, current) => acc + current);
      return "$" + sum;
    }
  }
  function handleCheckoutClick() {
    if (arrayOfId.length > 0) {
      navigate("/checkout/details");
    }
  }
  function handleDelete(id) {
    dispatch(removeFromCart(id));

    changeWidth();
  }
  function HandleIncrement(id) {
    dispatch(increment(id));
    setTrue(!isTrue);
  }
  function handleDecrement(id) {
    dispatch(decrement(id));
    setTrue(!isTrue);
  }

  return (
    <div className={cartCSS["cart-section"]}>
      <div className={cartCSS["cart-close"]}>
        <AiOutlineClose
          className={cartCSS["close-cart"]}
          onClick={props.handleClick}
        />
      </div>

      <h4 className={cartCSS["cart-heading"]}>
        shopping bag ({arrayOfId.length})
      </h4>
      <hr className={cartCSS["cart-line"]} />
      {arrayOfId.length === 0 ? (
        <div className={cartCSS["cart-empty"]}>
          <img
            className={cartCSS["cart-empty-image"]}
            src={emptyCartImage}
            alt="empty-cart"
          />
          <h4> your cart is empty</h4>
          <p> you can add to Cart using the plus button</p>
        </div>
      ) : (
        <div>
          <div ref={container} className={cartCSS["cart-container-body"]}>
            {arrayOfId.map((element) => {
              return (
                <div className={cartCSS["cart-container"]} key={element.id}>
                  <img
                    src={element.images[0]}
                    alt={element.name}
                    className={cartCSS["cart-image"]}
                  />
                  <div className={cartCSS["cart-container-details"]}>
                    <div className={cartCSS["cart-details-name"]}>
                      <h6>{element.name.substring(0, 22).trim()}</h6>
                      <p>{element.vendor}</p>
                    </div>
                    <div className={cartCSS["cart-plus-minus"]}>
                      <BsFileMinus
                        className={cartCSS["orange-hover"]}
                        onClick={() => handleDecrement(element.id)}
                      />
                      <h6>{element.numOfOrder}</h6>
                      <BsFilePlus
                        className={cartCSS["orange-hover"]}
                        onClick={() => HandleIncrement(element.id)}
                      />
                    </div>
                    <div className={cartCSS["cart-price-delete"]}>
                      <h6>{element.newPrice}</h6>
                      <AiOutlineDelete
                        className={cartCSS["orange-hover"]}
                        onClick={() => handleDelete(element.id)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={cartCSS["cart-ST"]}>
            <div className={cartCSS["cart-S"]}>
              <FaShippingFast className={cartCSS["free-shipping"]} />{" "}
              <h5>Free Shipping</h5>
            </div>
            <div className={cartCSS["cart-T"]}>
              <h5>SubTOtal: {totalPrice()}</h5>
            </div>
          </div>
        </div>
      )}

      <div className={cartCSS["cart-buttons"]}>
        <p
          className={cartCSS["cart-buttons-first-child"]}
          onClick={props.handleClick}
        >
          Continue shopping
        </p>
        <p
          className={
            arrayOfId.length === 0
              ? cartCSS["no-cart-items"]
              : cartCSS["cart-buttons-second-child"]
          }
          onClick={handleCheckoutClick}
        >
          Proceed to Checkout
        </p>
      </div>
    </div>
  );
}
export default Cart;
