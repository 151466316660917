import { useRef, useState, useEffect } from "react";
import {
  CgProfile,
  CgShoppingCart,
  CgSearch,
  CgLoadbarSound,
} from "react-icons/cg";
import { HiMenu } from "react-icons/hi";
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import header from "./header.module.css";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import Cart from "./Cart";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const cartTotal = useSelector((state) => state.cart.value);
  const [SearchParams, setSearchParams] = useSearchParams({ q: "" });
  const Search = SearchParams.get("q");
  const hamburger = useRef(null);
  const hamburgerMenu = useRef(null);
  const cart = useRef(null);
  const headerContainer = useRef(null);

  const TotalCart = cartTotal.length;

  const style = {
    fontSize: "2rem",
    margin: "auto 0",
  };
  function toggle() {
    setIsOpen(!isOpen);
  }

  function handleCartOpen() {
    if (cart.current) {
      cart.current.classList.toggle(`${header["cart-display"]}`);
    }
  }

  function openMenu() {
    document.body.style.overflow = "hidden";
    if (hamburgerMenu.current) {
      hamburgerMenu.current.classList.toggle(
        `${header["toggle-display-hamburger-menu"]}`
      );
    }
  }
  function toggleMenu() {
    setisMenuOpen(!isMenuOpen);
  }
  function closeMenu() {
    document.body.style.overflow = "scroll";
    if (hamburgerMenu.current) {
      hamburgerMenu.current.classList.toggle(
        `${header["toggle-display-hamburger-menu"]}`
      );
      setisMenuOpen(false);
    }
  }
  function handleOnChange(e) {
    setSearchParams({ q: e.target.value });
    props.handleSearchClick(e.target.value);
  }
  document.addEventListener("scroll", (event) => {
    if (headerContainer.current) {
      let bg = headerContainer.current;

      if (window.scrollY === 0) {
        bg.classList.remove(`${header["header-container"]}`);
      } else if (window.scrollY > 0) {
        bg.classList.add(`${header["header-container"]}`);
      }
    }
  });
  useEffect(() => {
    if (isOpen) {
      handleCartOpen();
    }
  }, [isOpen]);
  useEffect(() => {
    if (isMenuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [isMenuOpen]);
  return (
    <>
      <AnimatePresence>
        {isMenuOpen ? (
          <motion.div
            ref={hamburgerMenu}
            className={header["hamburger-menu"]}
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}>
            <div className={header["menu-container-section"]}>
              <div className={header["menu-container"]}>
                <div className={header["top"]}>
                  <div className={header["hamburger-heading-section"]}>
                    <CgLoadbarSound className={header["heading-icon"]} />
                    <h2>
                      Sweet <span className='green'> Sound</span>
                    </h2>
                  </div>

                  <FaTimes style={style} onClick={closeMenu} />
                </div>
                <ul>
                  <li>
                    <NavLink to='/'>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to='/about'>About</NavLink>
                  </li>
                  <li>
                    <NavLink to='/market'>Shop</NavLink>
                  </li>
                  <li>
                    <NavLink to='/contact'>Contact</NavLink>
                  </li>
                </ul>
                <div>
                  <div className={header["menu-btn"]}>
                    <p> Call Us</p>
                  </div>
                  <p>+7 800 123 45 67</p>
                </div>
                <div className={header["cookies"]}>
                  <Link to='#'>cookie information</Link>
                  <br />
                  <Link to='#'>legal & privacy</Link>
                </div>
                <ul className={header["header-social"]}>
                  <li className={header["header-social-icon"]}>
                    <span className='green'>
                      <FaInstagram />
                    </span>
                  </li>
                  <li className={header["header-social-icon"]}>
                    <span className='green'>
                      <FaTwitter />
                    </span>
                  </li>
                  <li className={header["header-social-icon"]}>
                    <span className='orange'>
                      <FaFacebook />
                    </span>
                  </li>
                  <li className={header["header-social-icon"]}>
                    <span className='orange'>
                      <FaLinkedin />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <div className={header.header} ref={headerContainer}>
        <div className={header["nav-bar-container"]}>
          <div className={header["nav-bar"]}>
            <div className={header["nav-bar-menu"]} ref={hamburger}>
              <HiMenu style={style} onClick={toggleMenu} />

              <div className={header["hamburger-heading-section"]}>
                <CgLoadbarSound className={header["heading-icon"]} />
                <h2>
                  Sweet <span>Sound</span>
                </h2>
              </div>
            </div>

            <div className={header["heading-section"]}>
              <CgLoadbarSound className={header["heading-icon"]} />
              <h2>
                Sweet <span> Sound</span>
              </h2>
            </div>

            <ul className={header["nav-bar-links"]}>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>
                <NavLink to='/about'>About</NavLink>
              </li>
              <li>
                <NavLink to='/market'>Shop</NavLink>
              </li>
              <li>
                <NavLink to='/contact'>Contact</NavLink>
              </li>
            </ul>
          </div>
          <div className={header["nav-logo"]}>
            <div className={header["nav-logo-items"]}>
              {props.boolean ? (
                <input
                  className={header["search-input"]}
                  type='search'
                  placeholder='Search ...'
                  value={Search}
                  onChange={(e) => handleOnChange(e)}
                />
              ) : (
                <Link to='/shop'>
                  <CgSearch className={header["header-SPC"]} />
                </Link>
              )}
            </div>

            <div className={header["nav-logo-items"]}>
              <Link className={header.profile} to='/login'>
                <CgProfile className={header["header-SPC"]} />
              </Link>
            </div>

            <div
              className={`${header["cart-icon"]} ${header["nav-logo-items"]}`}>
              {TotalCart > 0 ? (
                <span className={header["cart-number-display"]}>
                  {TotalCart}
                </span>
              ) : null}

              <CgShoppingCart
                onClick={toggle}
                className={header["header-SPC"]}
              />
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    ref={cart}
                    className={header["cart"]}
                    initial={{ x: "100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "100%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}>
                    <Cart handleClick={() => toggle()} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
