import Home from "./pages/home";
import About from "./pages/about/About";
import Shop from "./pages/shop/shop";
import Market from "./pages/shop/market";
import Contact from "./pages/contact/contact";
import NO_MATCH from "./pages/no_match";
import ProductPage from "./pages/productPage";
import Login from "./pages/profile/login";
import SIGN_IN from "./pages/profile/signin";
import SIGN_UP from "./pages/profile/signup";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Checkout from "./pages/checkout/Checkout";
import Successful from "./pages/checkout/successful";
import Details from "./components/details";
import Delivery from "./components/delivery";
import Confirm from "./components/Confirm";
import ProfilePage from "./pages/profile/profilePage";
import { useSelector } from "react-redux";

function App() {
  const isLogin = useSelector((state) => state.cart.isLogin);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="market/*" element={<Market />} />
        <Route path="shop" element={<Shop />} />
        <Route path="shop/product/:id/*" element={<ProductPage />} />
        <Route
          path="login"
          element={isLogin ? <Navigate to="/profile" /> : <Login />}
        >
          <Route index element={<Navigate to="sign_in" />} />
          <Route path="sign_in" element={<SIGN_IN />} />
          <Route path="sign_up" element={<SIGN_UP />} />
        </Route>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="checkout" element={<Checkout />}>
          <Route index element={<Navigate to="details" />} />
          <Route path="details" element={<Details />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="confirm" element={<Confirm />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        <Route path="successful" element={<Successful />} />
        <Route
          path="*"
          element={
            <NO_MATCH
              error="404"
              message="oops... the requested page does not exist"
            />
          }
        />
      </Routes>
    </>
  );
}

export default App;
