import testimonial from "./testimonial.module.css";
import React, { useState, useRef, useEffect } from "react";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { ImQuotesRight } from "react-icons/im";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function importAll(r) {
  let images = {};
  r.keys().forEach((key) => (images[key] = r(key)));
  return images;
}

const images = importAll(
  require.context("../assets/testimonial-pictures", false, /\.(png|jpe?g|svg)$/)
);

function Testimonial() {
  const quote = {
    textAlign: "center",
    margin: "auto 0",
  };
  let rightArrow = useRef(null);
  let leftArrow = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.2, // Percentage of the div that must be in view to trigger the animation
  });

  const customersReview = [
    {
      name: "samantha william and olivia chen",
      picture: images["./1.jpg"],
      year: "001",
      topic: "helps us improve our peoductivity",
      message:
        "Headsets are a great way to make phone calls without having to hold a phone to your ear. This can be especially useful for people who are on the phone for extended periods of time.",
    },
    {
      name: "Emma Martinez",
      picture: images["./2.jpg"],
      year: "002",
      topic: "To listen to music or other audio",
      message:
        "Whether you're listening to music, an audiobook, or a podcast, a headset can provide a more immersive and enjoyable listening experience than listening through your device's built-in speakers.",
    },
    {
      name: "Liam Patel",
      picture: images["./3.jpg"],
      year: "003",
      topic: "To play video games",
      message:
        "Many gamers use headsets to communicate with teammates or opponents during online gaming sessions. Headsets can provide clearer audio than using a built-in microphone or speakers, and can also help to reduce background noise.",
    },
    {
      name: "Ethan Kim",
      picture: images["./4.jpg"],
      year: "004",
      topic: "To participate in online meetings or webinars",
      message:
        "With more people working remotely, online meetings and webinars have become increasingly common. Using a headset can help to improve audio quality and reduce background noise, making it easier to communicate with others",
    },
  ];

  const [count, setCount] = useState(0);
  const [currentReview, setCurrentReview] = useState(customersReview[0]);

  useEffect(() => {
    setCurrentReview(customersReview[count]);
  }, [count]);
  function handleLeftClick() {
    let second = count - 1;
    rightArrow.current.classList.remove(testimonial.hidden);
    if (second <= 0) {
      leftArrow.current.classList.add(testimonial.hidden);
      second = 0;
    }
    setCount(second);
  }
  function handleRightClick() {
    let second = count + 1;
    leftArrow.current.classList.remove(testimonial.hidden);
    if (second >= 3) {
      rightArrow.current.classList.add(testimonial.hidden);
      second = 3;
    }
    setCount(second);
  }
  const animationVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 50,
    },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
      transition={{ duration: 0.8 }}
      className={testimonial["testimonial"]}
    >
      <h2>
        What Our <span className="green">Customers Are Saying</span>
      </h2>
      <hr className={testimonial["testimonial-line"]} />
      <div className={testimonial["testimonial-section"]}>
        <div className={testimonial["testimonial-section-1"]}>
          <div className={testimonial["testimonial-picture-container"]}>
            <img
              className={testimonial["testimonial-picture"]}
              src={currentReview.picture}
              alt="profile"
            />
            <div className={testimonial["testimonial-quote-icon"]}>
              <ImQuotesRight style={quote} />
            </div>
          </div>
        </div>
        <div className={testimonial["testimonial-section-2"]}>
          <h3 className={testimonial["testimonial-topic"]}>
            {currentReview.topic}
          </h3>
          <p className={testimonial["testimonial-message"]}>
            {currentReview.message}
          </p>
          <div>
            <p className={testimonial["name"]}>{currentReview.name}</p>
            <p className={testimonial["year"]}>{currentReview.year}</p>
          </div>
        </div>
      </div>
      <hr className={testimonial["testimonial-line"]} />
      <div className={testimonial["testimonial-arrows"]}>
        <div
          className={testimonial["arrow-container"]}
          onClick={handleLeftClick}
          ref={leftArrow}
        >
          <span className={testimonial["left-arrow"]}>
            <FaLongArrowAltLeft className={testimonial.arrowStyle} />
          </span>
        </div>

        <div
          className={testimonial["arrow-container"]}
          ref={rightArrow}
          onClick={handleRightClick}
        >
          <span className={testimonial["right-arrow"]}>
            <FaLongArrowAltRight className={testimonial.arrowStyle} />
          </span>
        </div>
      </div>
    </motion.div>
  );
}
export default Testimonial;
