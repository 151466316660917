import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import detailCSS from "./details.module.css";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useSelector } from "react-redux";

const Details = () => {
  const confirmUser = useLocation();

  const isLogin = useSelector((state) => state.cart.isLogin);
  const user = useSelector((state) => state.cart.user);
  const history = useNavigate();
  const [details, setDetails] = useOutletContext();
  const [profile, setProfile] = useState({
    email: "",
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    address: "",
    postcode: "",
  });
  useEffect(() => {
    if (isLogin) {
      setProfile({
        email: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        country: user.country,
        city: user.city,
        address: user.address,
        postcode: user.postcode,
      });
    } else if (confirmUser.state) {
      setProfile({
        email: confirmUser.state.email,
        firstname: confirmUser.state.firstname,
        lastname: confirmUser.state.lastname,
        country: confirmUser.state.country,
        city: confirmUser.state.city,
        address: confirmUser.state.address,
        postcode: confirmUser.state.postcode,
      });
    }
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleSubmit(e) {
    e.preventDefault();
    setDetails({
      user: profile,
    });
    history("/checkout/Delivery");
  }
  return (
    <>
      <form onSubmit={handleSubmit} className={detailCSS.section}>
        <div className={detailCSS.personal}>
          <h4>personal Details</h4>
          <Link to="/login">
            <h3>
              Log in <BsArrowRight />
            </h3>
          </Link>
        </div>
        <input
          type="email"
          placeholder="E-mail"
          value={profile.email}
          name="email"
          onChange={handleChange}
          required
        />
        <br />
        <div className={detailCSS.inputFlex}>
          <input
            type="text"
            placeholder="First name"
            value={profile.firstname}
            name="firstname"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            placeholder="Last name"
            value={profile.lastname}
            name="lastname"
            onChange={handleChange}
            required
          />
        </div>
        <div className={detailCSS.personal}>
          <h4>address</h4>
        </div>
        <div className={detailCSS.inputFlex}>
          <input
            type="text"
            placeholder="Country"
            value={profile.country}
            name="country"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            placeholder="City"
            value={profile.city}
            name="city"
            onChange={handleChange}
            required
          />
        </div>
        <div className={detailCSS.inputFlex}>
          <input
            type="text"
            placeholder="Address (street, house number)"
            value={profile.address}
            name="address"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            placeholder="Postcode"
            value={profile.postcode}
            name="postcode"
            onChange={handleChange}
            required
          />
        </div>
        <div className={detailCSS["register-label"]}>
          <label>
            <input type="checkbox" name="privacyPolicy" required />I have read
            and accept the{" "}
            <Link to="#" target="_blank" className={detailCSS["inner-link"]}>
              privacy policy
            </Link>
            .
          </label>
        </div>
        <button type="submit">continue to delivery</button>
      </form>
    </>
  );
};

export default Details;
