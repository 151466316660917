import React, { useCallback, useRef, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Collection from "../../components/collection";
import { Data } from "../../data";
import market from "./market.module.css";
import { Link, useNavigate } from "react-router-dom";
import Specification from "../../components/specification";
import Loading from "../../components/Loading";

import collectionImage from "../../assets/collection.jpg";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

function Market() {
  const navigate = useNavigate();
  const divRefs = useRef([]);
  const day = useRef(null);
  const hour = useRef(null);
  const minute = useRef(null);
  const second = useRef(null);
  const collectionSale = useRef(null);
  const [myData, setmyData] = useState(null);

  const divElements = divRefs.current;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    async function getData() {
      const result = await Data;
      setmyData(result);
    }
    getData();
  }, []);
  const handleClick = useCallback(
    (event) => {
      if (event && event.target) {
        for (let index = 0; index < divElements.length; index++) {
          divElements[index].style.height = 0;
        }
        var panel = event.target.parentNode.nextSibling;

        if (panel !== null) {
          panel.style.height = "190px";
        }
      }
    },
    [divElements]
  );
  if (!myData) {
    return <Loading />;
  }

  var countDownDate = new Date("may 10, 2027 15:37:25").getTime();

  const sales = myData.filter((element) => element.onSale[0] === true);
  const allProduct = myData;
  const audio = myData.filter(
    (element) =>
      element.vendor.replace("64", "").replace(" ", "").toLowerCase() ===
      "audio"
  );
  const AstellKern = myData.filter(
    (element) => element.vendor.toLowerCase() === "astell&kern"
  );
  const audeze = myData.filter(
    (element) => element.vendor.toLowerCase() === "audeze"
  );
  const focal = myData.filter(
    (element) => element.vendor.toLowerCase() === "focal"
  );

  const executeScroll = () => {
    if (collectionSale.current) {
      collectionSale.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const timer = setInterval(() => {
    if (day.current) {
      // get the cuurent time
      var now = new Date().getTime();
      // get the distnce between countdowndate and current date
      var distance = countDownDate - now;
      // time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      //  display the result on screen
      day.current.textContent = "19";
      hour.current.textContent = hours;
      minute.current.textContent = minutes;
      second.current.textContent = seconds;
    }

    // if the count down is finished
    if (distance < 0) {
      console.log(days);
      clearInterval(timer);
    }
  }, 1000);
  function handleShopClick(data) {
    navigate("/shop", {
      state: data,
    });
  }
  return (
    <div className={market["market-container"]}>
      <div>
        <div className={market["market-hero"]}>
          <Header boolean={false} />
          <div className={market["market-hero-section"]}>
            <div className={market["hero-text"]}>
              <h2>EXPLORE SWEET SOUND SHOP</h2>
              <Link to="/shop">
                <p>view</p>
              </Link>
            </div>
          </div>
        </div>

        <div className={market["accordion-container"]} id="collection">
          <h3>collection</h3>
          <hr className={market["accordion-line"]} />
          <div className={market["accordion-container-child"]}>
            <div className={market["accordion-image"]}>
              <img src={collectionImage} alt="collections" />
            </div>
            <div className={market["accordion-section"]}>
              <div className={market.accordion}>
                <button onClick={handleClick}>SHOP ALL</button>
                <HiOutlineArrowLongRight
                  onClick={() => handleShopClick(allProduct)}
                  className={market["accordion-arrow"]}
                />
              </div>
              <div
                style={{ height: "180px" }}
                className={market.panel}
                ref={(el) => (divRefs.current[0] = el)}
              >
                <p>
                  View the whole product including all other vendor (64 audio,
                  astell and kern, audeze and focal)
                </p>
              </div>
              <div className={market.accordion}>
                <button onClick={handleClick}>64 AUDIO</button>
                <div onClick={() => handleShopClick(audio)}>
                  <HiOutlineArrowLongRight
                    className={market["accordion-arrow"]}
                  />
                </div>
              </div>
              <div
                className={market.panel}
                ref={(el) => (divRefs.current[1] = el)}
              >
                <p>
                  64 Audio is a provider of custom in-Ear monitors aimed at the
                  working musician & has a universal range aimed at the High End
                  / Audiophile niche.
                </p>
              </div>
              <div onClick={handleClick} className={market.accordion}>
                <button>ASTELL AND KERN</button>
                <HiOutlineArrowLongRight
                  onClick={() => handleShopClick(AstellKern)}
                  className={market["accordion-arrow"]}
                />
              </div>
              <div
                className={market.panel}
                ref={(el) => (divRefs.current[2] = el)}
              >
                <p>
                  Astell&Kern is the ultimate digital music source that brings
                  you mastering Quality Sound or MQS. astell means "stars" in
                  Latin and Kern means "Center" in German we believe Astell&Kern
                  is the center of music.
                </p>
              </div>
              <div onClick={handleClick} className={market.accordion}>
                <button>AUDEZE</button>
                <HiOutlineArrowLongRight
                  onClick={() => handleShopClick(audeze)}
                  className={market["accordion-arrow"]}
                />
              </div>
              <div
                className={market.panel}
                ref={(el) => (divRefs.current[3] = el)}
              >
                <p>
                  Audeze is a california Based High-end audio manufacturer known
                  for delivering some of the most accurate sound reproduction
                  products available today.
                </p>
              </div>
              <div onClick={handleClick} className={market.accordion}>
                <button>FOCAL</button>
                <HiOutlineArrowLongRight
                  onClick={() => handleShopClick(focal)}
                  className={market["accordion-arrow"]}
                />
              </div>
              <div
                className={market.panel}
                ref={(el) => (divRefs.current[4] = el)}
              >
                <p>
                  FOcal has been creating benchmark acoustic equipment since
                  1979. COre businesses: speaker drivers and high-fidelity
                  Loudspeakers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={market.countdown} id="countdown">
          <h1>get discount on some product </h1>
          <p>
            lerum Checking the network cables, modem, and router Reconnecting to
            Wi-Fi Running Windows Network Diagnostics lerum Checking the network
            cables, modem, and router Reconnecting to Wi-Fi Running Windows
            Network Diagnostics
          </p>
          <hr className={market["accordion-line"]} />
          <h1 className={market["timer-container"]}>
            <span
              ref={day}
              className={market["timer"]}
              data-timer={"days"}
            ></span>
            :{" "}
            <span
              ref={hour}
              className={market["timer"]}
              data-timer={"hours"}
            ></span>
            :{" "}
            <span
              ref={minute}
              className={market["timer"]}
              data-timer={"minutes"}
            ></span>
            :{" "}
            <span
              ref={second}
              className={market["timer"]}
              data-timer={"seconds"}
            ></span>
          </h1>
          <button onClick={executeScroll} className={market["btn"]}>
            buy now
          </button>
        </div>

        <Specification />

        <div ref={collectionSale}>
          <Collection collection={sales} collectionType="product on sale" />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Market;
