import ProductCardCSS from "./Product-card.module.css";
import React, { useState, useEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate, Route, Routes } from "react-router-dom";
import ProductPage from "../pages/productPage";
import { addToCart } from "../redux/cartSlice";
import { useDispatch } from "react-redux";
function ProductCard(props) {
  const [sale, setSale] = useState(true);
  const isOnSale = props.product.onSale[0] || false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = {
    fontSize: "2rem",
    display: "grid",
    placeItem: "center",
  };
  useEffect(() => {
    handleLoad();
  });
  function handleLoad() {
    setSale(isOnSale);
  }
  function handleClick() {
    const id = props.product.id;
    navigate(`/shop/product/${id}`);
  }

  function handleAddToCart() {
    dispatch(addToCart(props.product.id));
  }
  return (
    <div key={props.product.id} className={ProductCardCSS["product-card"]}>
      {sale ? <span className={ProductCardCSS["sale"]}>SALE </span> : null}
      <div className={ProductCardCSS["image-container"]} onClick={handleClick}>
        <img
          className={ProductCardCSS["product-card-image"]}
          src={props.product.images}
          alt={props.product.name}
        />
        <div className={ProductCardCSS["product-card-name-section"]}>
          <h5 className={ProductCardCSS["product-card-name"]}>
            {props.product.name}
          </h5>
        </div>
        <p className={ProductCardCSS["vendor"]}> {props.product.vendor}</p>
      </div>
      <div className={ProductCardCSS["price-and-cart"]}>
        <div className={ProductCardCSS["price"]}>
          <h3 className={sale ? ProductCardCSS.onSale : null}>
            {props.product.price}
          </h3>
          {sale ? <h4>{props.product.onSale[1]}</h4> : null}
        </div>
        <div className={ProductCardCSS["btn-style"]} onClick={handleAddToCart}>
          <BiPlus style={style} />
        </div>
      </div>
      <Routes>
        <Route path={`/product-page`} element={<ProductPage />} />
      </Routes>
    </div>
  );
}
export default ProductCard;
