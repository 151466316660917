import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Filter from "../../components/filter";
import Loading from "../../components/Loading";
import { Data } from "../../data";

import Collection from "../../components/collection";
import { useLocation } from "react-router-dom";
import market from "./market.module.css";
function Shop() {
  const location = useLocation();
  let theProduct = location.state;

  const [search, setSearch] = useState("");
  const [myData, setmyData] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    async function getData() {
      const result = await Data;
      setmyData(result);
    }
    getData();
  }, []);
  if (!myData) {
    return <Loading />;
  }
  const Sale = myData.filter(
    (product) => product.onSale && product.onSale[0] === true
  );
  function handleSearchClick(search) {
    setSearch(search);
  }

  return (
    <div className={market["shop"]}>
      <div className={market["shop-header"]}>
        <Header
          boolean={true}
          handleSearchClick={(search) => {
            handleSearchClick(search);
          }}
        />
      </div>

      <Filter search={search} filtered={theProduct} />
      <Collection
        collection={Sale}
        collectionType={`collection sale for 2023`}
      />
      <Footer />
    </div>
  );
}

export default Shop;
