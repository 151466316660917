import { CgLoadbarSound } from "react-icons/cg";
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaCcMastercard, FaCcVisa, FaCcAmex } from "react-icons/fa";
import footer from "./footer.module.css";
import { NavLink } from "react-router-dom";
function Footer() {
  const date = new Date().getFullYear();
  return (
    <div className={footer["footer"]}>
      <div className={footer["footer-section-1"]}>
        <div className={footer["heading-section"]}>
          <CgLoadbarSound className={footer["heading-icon"]} />
          <h2>
            Sweet <span> Sound</span>
          </h2>
        </div>
        <ul>
          <li>
            <NavLink to='/'>Home</NavLink>
          </li>
          <li>
            <NavLink to='/about'>About</NavLink>
          </li>
          <li>
            <NavLink to='/market'>Shop</NavLink>
          </li>

          <li>
            <NavLink to='/Contact'>Contact</NavLink>
          </li>
        </ul>

        <ul className={footer["social-icons"]}>
          <li className={footer["social-icon"]}>
            <span className='green'>
              <FaInstagram />
            </span>
          </li>
          <li className={footer["social-icon"]}>
            <span className='green'>
              <FaTwitter />
            </span>
          </li>
          <li className={footer["social-icon"]}>
            <span className='orange'>
              <FaFacebook />
            </span>
          </li>
          <li className={`${footer["social-icon"]} ${footer["last"]}`}>
            <span className='orange'>
              <FaLinkedin />
            </span>
          </li>
        </ul>
      </div>
      <div className={footer["footer-section-2"]}>
        <p>Copyright © 2020 - {date} Sweet Sound. All rights reserved.</p>
      </div>
      <div className={footer["payment-icons"]}>
        <FaCcMastercard className={footer.paymentIcon} />
        <FaCcVisa className={footer.paymentIcon} />
        <FaCcAmex className={footer.paymentIcon} />
      </div>
    </div>
  );
}
export default Footer;
