import React, { useState } from "react";
import registerCSS from "./register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../api/api";
import { useDispatch } from "react-redux";
import { userLogin } from "../../redux/cartSlice";
import GoogleLoginPage from "./googlelogin";
const Signin = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [signIn, setSignIn] = useState({
    email: "",
    password: "",
  });

  const signinMutation = useMutation(login, {
    onError: (err) => {
      setError(err.response.data);
      console.log("the error:", err.response.data);
    },
    onSuccess: (theData) => {
      dispatch(userLogin(theData.data));
      history("/profile");
    },
  });
  function handleSignIn(e) {
    e.preventDefault();
    signinMutation.mutate(signIn);
    setSignIn({
      email: "",
      password: "",
    });
  }

  function handleSignInChange(e) {
    const { name, value } = e.target;

    setSignIn((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  return (
    <div>
      <div className={registerCSS["sign-in"]}>
        <div className={registerCSS["heading"]}>
          <h2> Log in to your account</h2>
          <p>
            Don't have an account?{" "}
            <span>
              <Link to="/login/sign_up">Sign Up</Link>
            </span>
          </p>
        </div>
        <GoogleLoginPage />

        <h2 className={registerCSS.h2}>
          <span>Or with email and password</span>
        </h2>
        <form
          action="/login/password"
          method="post"
          className={registerCSS["register-form"]}
          onSubmit={handleSignIn}
        >
          <label htmlFor="login-email" className={registerCSS.label}>
            Email address
          </label>
          <br />
          <input
            onChange={handleSignInChange}
            onInput={handleSignInChange}
            className={registerCSS["register-input"]}
            type="email"
            id="login-email"
            name="email"
            value={signIn.email}
            placeholder="type your Email Address"
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            title="enter a valid email"
            required
          />
          <br />
          <label htmlFor="login-password" className={registerCSS.label}>
            password
          </label>
          <br />
          <input
            onChange={handleSignInChange}
            onInput={handleSignInChange}
            className={registerCSS["register-input"]}
            type="password"
            id="login-password"
            name="password"
            value={signIn.password}
            placeholder="type your Password"
            pattern=".{4,}"
            title="four or more characters"
            required
          />
          <br />
          <p className={registerCSS["errorMessage"]}>
            {signinMutation.isError ? error : null}
          </p>
          <input
            className={registerCSS["register-submit"]}
            type="submit"
            value={signinMutation.isLoading ? "loading" : "sign in"}
          />
        </form>
      </div>
    </div>
  );
};

export default Signin;
