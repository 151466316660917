import React, { useState, useRef } from "react";
import registerCSS from "./register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { newProfile } from "../../api/api";
import { useDispatch } from "react-redux";
import { userLogin } from "../../redux/cartSlice";
import GoogleLoginPage from "./googlelogin";
const Signup = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const errorMessage = useRef(null);
  const [error, setError] = useState(null);
  const [register, setRegister] = useState({
    email: "",
    password: "",
    verifyPassword: "",
    privacyPolicy: false,
    firstName: "",
    lastName: "",
  });

  const signupMutation = useMutation(newProfile, {
    onError: (err) => {
      setError(err.response.data);
      console.log("the error:", err.response.data);
    },
    onSuccess: (theData) => {
      dispatch(userLogin(theData.data));
      history("/profile");
    },
  });
  function handleRegister(e) {
    console.log("first");
    e.preventDefault();
    if (register.password === register.verifyPassword) {
      errorMessage.current.textContent = "";
      signupMutation.mutate(register);

      setRegister({
        email: "",
        password: "",
        verifyPassword: "",
        privacyPolicy: false,
      });
    } else {
      errorMessage.current.textContent =
        "password and repeated password doesn't match";
    }
  }
  function handleRegisterChange(e) {
    const { name, value } = e.target;
    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleChecked(e) {
    setRegister((prevState) => ({
      ...prevState,
      privacyPolicy: e.target.checked,
    }));
  }
  return (
    <div>
      <div className={registerCSS["register"]}>
        <div className={registerCSS["heading"]}>
          <h2> Create your account</h2>
          <p>
            Have an account?{" "}
            <span>
              <Link to="/login/sign_in">Log in now</Link>
            </span>
          </p>
        </div>

        <GoogleLoginPage />
        <h2 className={registerCSS.h2}>
          <span>Or with email and password</span>
        </h2>
        <form
          action="/signup"
          method="POST"
          onSubmit={handleRegister}
          className={registerCSS["register-form"]}
        >
          <label htmlFor="username" className={registerCSS.label}>
            email address
          </label>
          <br />
          <input
            onChange={handleRegisterChange}
            onInput={handleRegisterChange}
            className={registerCSS["register-input"]}
            type="email"
            id="username"
            name="email"
            value={register.email}
            placeholder="type your email Address"
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            title="enter a valid email"
            required
          />
          <br />
          <label htmlFor="firstName" className={registerCSS.label}>
            First name
          </label>
          <br />
          <input
            onChange={handleRegisterChange}
            onInput={handleRegisterChange}
            className={registerCSS["register-input"]}
            type="text"
            id="firstName"
            name="firstName"
            value={register.firstName}
            placeholder="type your first name"
            pattern="^[a-zA-Z]+$"
            title="Please enter a valid first name with only letters (spaces, numbers and special characters are not allowed)"
            required
          />
          <br />
          <label htmlFor="lastName" className={registerCSS.label}>
            Last name
          </label>
          <br />
          <input
            onChange={handleRegisterChange}
            onInput={handleRegisterChange}
            className={registerCSS["register-input"]}
            type="text"
            id="lastName"
            name="lastName"
            value={register.lastName}
            placeholder="type your last name"
            pattern="^[a-zA-Z]+$"
            title="Please enter a valid last name with only letters (spaces, numbers and special characters are not allowed)"
            required
          />
          <br />
          <label htmlFor="password" className={registerCSS.label}>
            password
          </label>
          <br />
          <input
            onChange={handleRegisterChange}
            onInput={handleRegisterChange}
            className={registerCSS["register-input"]}
            type="password"
            id="password"
            name="password"
            value={register.password}
            placeholder="type your Password"
            pattern=".{4,}"
            title="four or more characters"
            required
          />
          <br />
          <label htmlFor="repeat-password" className={registerCSS.label}>
            repeat password
          </label>
          <br />
          <input
            onChange={handleRegisterChange}
            onInput={handleRegisterChange}
            className={registerCSS["register-input"]}
            type="password"
            id="repeat-password"
            name="verifyPassword"
            value={register.verifyPassword}
            placeholder="type your Password again"
            pattern=".{4,}"
            title="four or more characters"
            required
          />
          <br />
          <div className={registerCSS["register-label"]}>
            <label>
              <input
                onChange={handleChecked}
                type="checkbox"
                name="privacyPolicy"
                checked={register.privacyPolicy}
                required
              />
              I have read and accept the
              <Link
                to="#"
                target="_blank"
                className={registerCSS["inner-link"]}
              >
                privacy policy
              </Link>
              .
            </label>
          </div>
          <br />
          <p ref={errorMessage} className={registerCSS.errorMessage}></p>
          <p className={registerCSS.errorMessage}>
            {signupMutation.error ? error : null}
          </p>
          <input
            className={registerCSS["register-submit"]}
            type="submit"
            value={signupMutation.isLoading ? "Loading.." : "sign up"}
          />
        </form>
      </div>
    </div>
  );
};

export default Signup;
