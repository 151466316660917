import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Data } from "../data";
import { addToCart } from "../redux/cartSlice";
import { FiAlertCircle } from "react-icons/fi";
import { TbTruckDelivery, TbMathGreater } from "react-icons/tb";
import { useParams, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import productPageCSS from "./productPage.module.css";
import Collection from "../components/collection";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
const ProductPage = () => {
  const selector = useSelector((state) => state.cart.value.length);
  const [onsale, setOnSale] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myData, setmyData] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    async function getData() {
      const result = await Data;
      setmyData(result);
    }
    getData();
  }, []);
  if (!myData) {
    return <Loading />;
  }

  const product = myData.filter((element) => {
    return element.id === Number(params.id);
  });

  const productVendor = myData.filter((element) => {
    return element.vendor === product[0].vendor;
  });

  function handleClick() {
    dispatch(addToCart(product[0].id));
  }
  function handleCheckout() {
    navigate("/checkout");
  }
  function handleLoad() {
    setOnSale(product[0].onSale[0]);
  }
  return (
    <div onLoad={handleLoad} key={product[0].id}>
      <div className={productPageCSS["product-page-header"]}>
        <Header />
      </div>

      <div className={productPageCSS["product-page"]}>
        <div className={productPageCSS["product-page-nav"]}>
          <HashLink to="/market#collection">
            <h3>Collection</h3>
          </HashLink>

          <TbMathGreater />
          <h3 onClick={() => navigate("/shop", { replace: true })}>
            All product
          </h3>

          <TbMathGreater />
          <h3
            onClick={() =>
              navigate(`${window.location.pathname}`, { replace: false })
            }
          >
            {product[0].name}
          </h3>
        </div>
        <div className={productPageCSS["product-page-1-section"]}>
          <div className={productPageCSS["product-page-image-container"]}>
            <img
              className={productPageCSS["product-page-image"]}
              src={product[0].images}
              alt={product[0].name}
            />
          </div>

          <div className={productPageCSS["product-page-detail"]}>
            <h4>{product[0].vendor}</h4>
            <h3>{product[0].name}</h3>
            {onsale ? (
              <div className={productPageCSS["product-page-onSale"]}>
                <p>On Sale</p>
                <FiAlertCircle />
              </div>
            ) : null}
            <div className={productPageCSS["product-page-price"]}>
              <p className={onsale ? productPageCSS.onSale : null}>
                {product[0].price}
              </p>
              {onsale ? <p>{product[0].onSale[1]}</p> : null}
            </div>
            <ul>
              {product[0].features.map((element, index) => (
                <li key={index}>{element}</li>
              ))}
            </ul>
            <div className={productPageCSS["product-page-delivery"]}>
              <div className={productPageCSS["product-page-delivery-in-stock"]}>
                <TbTruckDelivery /> <p>in Stock and Shipping Now</p>
              </div>
              <p>
                Order within
                <span className="green"> the first 12 hours of (GMT-7) </span>to
                be shipped <b>today</b>
              </p>
            </div>
            <div className={productPageCSS["product-page-container"]}>
              <p
                className={productPageCSS["product-page-btn"]}
                onClick={handleClick}
              >
                Add to Cart
              </p>

              <p
                className={
                  selector < 1
                    ? productPageCSS["product-page-btn-disable"]
                    : productPageCSS["product-page-btn"]
                }
                onClick={selector < 1 ? null : handleCheckout}
              >
                checkout cart
              </p>
            </div>
          </div>
        </div>
      </div>
      <Collection
        collection={productVendor}
        collectionType="You might also like these"
      />

      <Footer />
    </div>
  );
};

export default ProductPage;
