import React from "react";
import mapCSS from "./map.module.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default function Map() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once when the element comes into view
  });
  const leafletCSS = {
    borderwidth: "3px",
    borderStyle: "solid",
    borderColor: "#ff7a24",
    borderRadius: "30px",
    height: "400px",
    width: "100%",
  };
  return (
    <div className={mapCSS["map-container"]}>
      <motion.div
        ref={ref}
        className={`fasd-in-view ${inView ? "in-view" : ""}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <h3>
          {" "}
          you can visit us <span className="orange"> here</span>
        </h3>
        <div className={mapCSS.mapContainer}>
          <div className={mapCSS.mapSection}>
            <div className={mapCSS.map}>
              <MapContainer
                center={[43.039, -87.93]}
                zoom={55}
                scrollWheelZoom={false}
                style={leafletCSS}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker className="orange" position={[43.039, -87.93]}>
                  <Popup>
                    1610 W Wisconsin Ave, Milwaukee,
                    <br /> WI 53233, United States
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
          <div className={mapCSS["map-second-child"]}>
            <h5>1610 W Wisconsin Ave, Milwaukee, WI 53233, United States</h5>
            <p>monday-Friday: 8:30 -18:30</p>
            <p>Saturday: 9:00-16:00</p>
            <h5>Call Centre +12345678 / +12345678 (Mon-Fri)</h5>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
