import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

async function getData() {
  try {
    const response = await axios.get(`${API_URL}/data`);
    const responseData = response.data;
    console.log("Response Data:", responseData);
    return responseData;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error; // Re-throw the error to handle it outside of this function if needed
  }
}

export const Data = getData();
