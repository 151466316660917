import React, { useEffect } from "react";
import Header from "../../components/Header";
import { HiArrowLongRight } from "react-icons/hi2";
import loginCSS from "./login.module.css";
import { Outlet } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Login() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div>
      <div className={loginCSS.loginHeader}>
        <Header />
      </div>

      <div className={loginCSS.container}>
        <div className={loginCSS.container1}>
          <Outlet />
        </div>
        <div className={loginCSS.container2}>
          <div className={loginCSS.child}>
            <h2>sweet sounds have some few product on sale!</h2>
            <p>
              Attention shoppers! It's time to indulge in some unbeatable deals!
              our sale has something for everyone.Explore our profile now and
              treat yourself to the ultimate shopping spree. Shop smart, save
              big.
            </p>
            <HashLink to="/market#countdown">
              <button>
                {" "}
                View the Shop <HiArrowLongRight />
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
