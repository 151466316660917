import { createSlice } from "@reduxjs/toolkit";
import { Data } from "../data";
let data = "";
async function myFunction() {
  data = await Data;
}

myFunction();

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: [],
    storeIndex: [],
    isLogin: false,
    user: {},
  },
  reducers: {
    userLogin: (state, action) => {
      const user = action.payload;
      state.isLogin = true;
      state.user = user;
    },
    userLogout: (state, action) => {
      state.isLogin = false;
      state.user = null;
    },
    addToCart: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      let newState = [...state.value];

      if (!state.storeIndex.includes(action.payload)) {
        const productAddedToCart = data.filter((element) => {
          if (element.id === action.payload) {
            state.storeIndex.push(element.id);

            return element.id === action.payload;
          }
        });
        const newProductAddedToCart = productAddedToCart.map((element) => {
          var price = "";
          if (element.onSale[0]) {
            price = element.onSale[1];
          } else {
            price = element.price;
          }
          return Object.assign({}, element, { numOfOrder: 1, newPrice: price });
        });
        const NewState = [...newState, ...newProductAddedToCart];
        state.value = NewState;
      }
    },
    increment: (state, action) => {
      const itemId1 = action.payload;
      const newState3 = [...state.value];

      var number = "";
      let ano = [];
      newState3.map((element) => {
        if (element.id === itemId1) {
          var count = element.numOfOrder + 1;
          var price;
          if (element.onSale[0]) {
            price = element.onSale[1];
          } else {
            price = element.price;
          }

          price = price.replace("$", "");
          price = price.replace(",", "");
          price = "$" + price * count;

          number = count;
          ano.push(price);
        }
      });
      const NewState1 = newState3.map((item) => {
        if (item.id === itemId1) {
          // Update properties of items that satisfy the condition
          return Object.assign({}, item, {
            newPrice: ano[0],
            numOfOrder: number,
          });
        } else {
          // Keep other items unchanged
          return item;
        }
      });

      const updatedState = [...NewState1];
      state.value = updatedState;
    },
    decrement: (state, action) => {
      const itemId2 = action.payload;
      const newState4 = [...state.value];

      var number1 = "";
      let ano1 = [];

      newState4.map((element) => {
        if (element.id === itemId2) {
          var count1 = element.numOfOrder - 1;
          if (count1 < 1) {
            count1 = 1;
          }

          if (element.onSale[0]) {
            var price = element.onSale[1];
          } else {
            var price = element.price;
          }

          price = price.replace("$", "");
          price = price.replace(",", "");
          price = "$" + price * count1;
          number1 = count1;
          ano1.push(price);
        }
      });
      const NewState2 = newState4.map((item) => {
        if (item.id === itemId2) {
          // Update properties of items that satisfy the condition
          return Object.assign({}, item, {
            newPrice: ano1[0],
            numOfOrder: number1,
          });
        } else {
          // Keep other items unchanged
          return item;
        }
      });

      const updatedState1 = [...NewState2];
      state.value = updatedState1;
    },
    removeFromCart: (state, action) => {
      const itemId = action.payload;
      let newState2 = [...state.value];
      // Use filter to create a new array without the item to be removed
      const theNewState = newState2.filter((item) => item.id !== itemId);
      const index2 = state.storeIndex.indexOf(itemId);
      state.storeIndex.splice(index2, 1);
      // Return the updated state
      state.value = theNewState;
    },
  },
});

export const {
  userLogin,
  userLogout,
  increment,
  decrement,
  addToCart,
  removeFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;
