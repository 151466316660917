import checkoutCSS from "./checkout.module.css";
import Header from "../../components/Header";
import BagItem from "../../components/BagItem";
import { RxSlash } from "react-icons/rx";
import { useState, useEffect } from "react";
import emptyCartImage from "../../assets/empty-cart.png";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const Checkout = () => {
  const state = useSelector((state) => state.cart.value);
  const [details, setDetails] = useState({
    deliveryMethod: null,
    user: null,
  });
  const navigate = useNavigate();
  function handleClick() {
    navigate("/shop");
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className={checkoutCSS.checkoutHead}>
        <Header />
      </div>

      <div className={checkoutCSS.checkout}>
        <div className={checkoutCSS["checkout-container"]}>
          <div className={checkoutCSS.nav}>
            <h3>
              <NavLink
                style={{ pointerEvents: "none" }}
                to="/checkout/details"
                activeclassname={checkoutCSS.vactive}
              >
                Details
              </NavLink>
            </h3>
            <RxSlash />
            <h3>
              <NavLink
                style={{ pointerEvents: "none" }}
                to="/checkout/Delivery"
                activeclassname={checkoutCSS.vactive}
              >
                Delivery
              </NavLink>
            </h3>
            <RxSlash />
            <h3>
              <NavLink
                style={{ pointerEvents: "none" }}
                to="/checkout/confirm"
                activeclassname={checkoutCSS.vactive}
              >
                Confirm{" "}
              </NavLink>
            </h3>
          </div>
          <Outlet context={[details, setDetails]} />
        </div>
        <div className={checkoutCSS["bag-container"]}>
          <h3>YOUR BAG</h3>
          {state.length > 0 ? (
            <>
              <hr className={checkoutCSS["bag-line"]} />

              <BagItem element={state} />
            </>
          ) : (
            <>
              <img
                className={checkoutCSS.emptyImage}
                src={emptyCartImage}
                alt="empty cart"
              />
              <h3 className={checkoutCSS.empty}>
                Your cart is empty, click the botton below to explore our shop
              </h3>

              <button className={checkoutCSS.btn} onClick={handleClick}>
                explore shop
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkout;
