import React from "react";

import noMatch from "./no_match.module.css";
import brokenRobot from "../assets/broken-robot.png";
import { Link } from "react-router-dom";

export default function no_match(props) {
  window.scrollTo({ top: 0, left: 0 });

  return (
    <div className={noMatch["no-match"]}>
      <div className={noMatch["section-404"]}>
        <h1 className={noMatch["text-404"]}>{props.error}</h1>
        <img
          className={noMatch["image-404"]}
          src={brokenRobot}
          alt="broken-bobot"
        />
      </div>

      <h4 className="green">
        {props.message}
        <span></span>
      </h4>
      <Link to="/">
        <button className={noMatch["btn-404"]}>GO BACK HOME</button>
      </Link>
    </div>
  );
}
