import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
async function newProfile(userData) {
  // Make the API request to sign up the user
  const response = await axios.post(`${API_URL}/signup`, userData);

  // Return the data from the response

  return response;
}

async function login(userData) {
  const username = userData.email;
  const password = userData.password;
  // Make the API request to login the user
  const response = await axios.post(`${API_URL}/login`, {
    username,
    password,
  });

  // Return the data from the response
  return response;
}
async function updateProfile(userData) {
  // Make the API request to update the user
  const response = await axios.post(`${API_URL}/update`, userData);

  // Return the data from the response
  return response;
}
async function googleAuth(data) {
  // Make the API request to update the user
  const response = await axios.post(`${API_URL}/google`, data);

  // Return the data from the response

  return response;
}
async function stripeCheckout(data) {
  // Make the API request to update the user
  const response = await axios.post(`${API_URL}/create-checkout-session`, data);

  // Return the data from the response

  return response.data.url;
}
async function contactRoute(data) {
  // Make the API request to update the user
  const response = await axios.post(`${API_URL}/contact-me`, data);

  // Return the data from the response

  return response.data;
}

export {
  newProfile,
  login,
  updateProfile,
  googleAuth,
  stripeCheckout,
  contactRoute,
};
